.searchBarDiv{
height: 100%;
display: flex;
z-index: 0;
width: fit-content;
width: 100%;
max-width: 430px;
margin: 10px auto 10px auto;

}
.searchBar{
    border: none;
    border-radius: 5px;
    border-top-right-radius: 0px;
    width: 100%;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    text-align: right;
    transition: 0.2s ease-in-out all;
    border-right: none;
    background-color: #CCD9F7;
    padding:5px 4px 5px 0px;
}
.searchBar:focus{
    border-color: none !important;
    outline: none;
    border:none !important; 
}
.searchBtn{
    align-content: center;
    background-color: #CCD9F7;
    padding: 7px 13px 0px 5px;
    border-top-right-radius: 5px;
    transition: 0.1s ease-in-out all;
    border-bottom-right-radius: 5px;
    z-index: 3;
    border: none;
}

.clearBtn{
    position: absolute
}
