.divContainer{
    background-color: #EEEEEE !important;
    margin-top: 40px;
    
}   
.introDiv{
    height: 98vh;
    width: 100%;
    position: relative;
    
}
.blueSection{
    height: 50%;
    background-color: #C9E5F3;
}
.animatedSectionDiv{
    position: absolute;
    background-color: #EEEEEE !important;
    max-width: 330px;
    max-height: 330px;
    width: 100%;
    border-radius: 1000px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding:0px;
    height: 100%;
}
.introDiv h1{
    color: #1043A9;
    font-family: Dana1;
    font-size: 30px;
    text-align: center;
    margin: 170px auto 0px auto;
}
.arrowDiv{
    height: 200px;
    width: 200px;
    margin-top: -35px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(90deg);
}
.blueLine{
    width: 100%;
    height: 10px;
    background-color: #C9E5F3;
}
.card1{
    height: 250px;
    width: 100%;
    padding: 30px 0px 30px 0px;
}
.circle{
    max-width: 200px;
    width: 100%;
    height: 200px;
    background-color: #F5F5F5;
    border-radius: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardInfo{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardInfo h2{
    color: #0A58CF;
    margin-bottom: 10px;
    font-family: Dana1;
}
.cardInfo p{
    line-height: 30px;
    color: #273C75;
    margin: 0px !important;
    padding: 0px !important;
}
.circle img{
    max-width: 120px;
    width: auto;
}

.rowStyle{
    max-width: 1100px;
}
.ovDiv{
    display:flex !important;
     padding:0px !important;
     width: 100%;
}
.wapper{
    width: 100%;
    max-width: 200px;
}

@media only screen and (max-width: 1200.98px) {
    .card1{
        height: 250px;
        width: 100%;
        padding: 30px 200px 30px 200px;
    }
}
@media only screen and (max-width: 992.98px) {
    .card1{
        height: 250px;
        width: 100%;
        padding: 30px 120px 30px 120px;
    }
}
@media only screen and (max-width: 768.98px) {
    .animatedSectionDiv{
        position: absolute;
        background-color: #EEEEEE !important;
        max-width: 260px;
        max-height: 260px;
        width: 100%;
        border-radius: 1000px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
    }
    .introDiv h1{
        font-size: 20px;
        padding: 0px 50px;
        line-height: 30px;
        margin-top: 150px;
    }
    .arrowDiv{
      margin-top: -40px;
    }
    .introDiv{
        height: 74vh;

        
    }
    .arrowDiv{
        height: 150px;
        width: 150px;
        margin-top: -35px;
        margin-left: auto;
        margin-right: auto;
        transform: rotate(90deg);
    }
    .card1{
       height: 350px;
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 20px 100px 20px 100px;
   }
   .circle img{
    width: 90px;
    text-align: center;
    }
    .circle{
        width: 130px;
        height: 130px;
        background-color: #F5F5F5;
        border-radius: 100%;
        display: flex;
        margin: 0px auto 20px auto;
        align-items: center ;
        text-align: center;
        justify-content: center;
    }
    .colCenter{
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
    }
    .cardInfo{
        height:auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 10px;
        padding: 0px 25px 0px 25px !important;
    }
    .cardInfo h2{
        width: 100%;
        color: #0A58CF;
        margin-bottom: 10px;
        font-family: Dana1;
        font-size: 22px;
        text-align: center;
    }
    .cardInfo p{
        width: 100%;
        line-height: 25px;
        color: #273C75;
        margin: 0px !important;
        text-align: center;
        font-size: 14px;
        padding: 0px !important;
    }
    .ovDiv{
        display:block !important;
        padding:30px 0px 30px 0px !important;

    }
    .wapper{
        max-width: 100% !important;
        margin: 0px auto 0px auto;
        display:flex;
        justify-content: center;
        align-items: center;
    }
 }
@media only screen and (max-width: 576px) {
    .animatedSectionDiv{
        position: absolute;
        background-color: #EEEEEE !important;
        max-width: 260px;
        max-height: 260px;
        width: 100%;
        border-radius: 1000px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
    }
    .introDiv h1{
        font-size: 20px;
        padding: 0px 50px;
        line-height: 30px;
        margin-top: 150px;
    }
    .arrowDiv{
      margin-top: -40px;
    }
    .introDiv{
        height: 95vh;

        
    }
    .arrowDiv{
        height: 150px;
        width: 150px;
        margin-top: -35px;
        margin-left: auto;
        margin-right: auto;
        transform: rotate(90deg);
    }
    .card1{
       height: 300px;
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 50px 20px 50px 20px;
   }
   .circle img{
    width: 90px;
    text-align: center;
    }
    .circle{
        width: 130px;
        height: 130px;
        background-color: #F5F5F5;
        border-radius: 100%;
        display: flex;
        margin: 0px auto 20px auto;
        align-items: center ;
        text-align: center;
        justify-content: center;
    }
    .colCenter{
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
    }
    .cardInfo{
        height: 100%;
        width: 100%;
        display: block;
        text-align: right;
        justify-content: center
    }
    .cardInfo h2{
        width: 100%;
        color: #0A58CF;
        margin-bottom: 10px;
        font-family: Dana1;
        font-size: 22px;
    }
    .cardInfo p{
        width: 100%;
        line-height: 25px;
        color: #273C75;
        margin: 0px !important;
        font-size: 14px;
        padding: 0px !important;
    }
    .rowStyle{
        max-width: 100%;
    }

}
 @media only screen and (max-width: 356px) {
    .animatedSectionDiv{
        position: absolute;
        background-color: #EEEEEE !important;
        max-width: 210px;
        max-height: 210px;
        width: 100%;
        border-radius: 1000px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
    }
    .introDiv{
        height: 93vh;
    }
    .introDiv h1{
        font-size: 15px;
        padding: 0px 50px;
        line-height: 25px;
        margin-top: 120px;
    }
 }