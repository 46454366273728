
  
  h3 {
    font-size: 20px;
  }
  
  h3+p {
    margin-top: auto;
  }
  
  
  
  .wrapper {
    width: 90%;
    max-width: 940px;
    margin: 20px auto;
    background-color: #fff;
  }
  
  .homeHero {
    display: grid;
    grid-gap: 1px;
    grid-auto-rows: minmax(150px, auto);
  }
  
  .homeHero > * {
    padding: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #333;
    color: #fff;
  }
  
  .feature {
    /* background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/12005/hero-demo1.jpg); */
    background-position: bottom left;
    color: #fff;
  }
  
  .photos {
    /* background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/12005/hero-demo2.jpg);  */
    color: #fff;

  }
  
  .news {
    /* background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/12005/hero-demo3.jpg);  */
    color: #fff;
  }
  
  .special {
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8)),url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/12005/hero-demo5.jpg); 
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .special h3,
  .feature h3,
  .news h3,
  .photos h3,
  .cta h3,
  .amazing h3{
    font-size: 16px;
    font-family: Dana2;
    margin-bottom: 0px !important;
  }
  

  .amazing {
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/12005/hero-demo4.jpg); 
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .cta {
    background-color: rgb(123,14,34);
    color: #fff;
    display: flex;
    flex-direction: column;
    
  }
  
  a.spamButton:link {
    border: 1px solid #ccc;
    border-radius: 3px;
    text-decoration: none;
    background-color: #fff;
    color: #333;
    display: block;
    padding: 5px;
    margin: auto auto 20px;
    width: 90%;
    text-align: center;
  }
  
  @media (min-width: 500px) {
    .homeHero {
      grid-template-columns: 1fr 1fr;
    }
    
    .feature {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
    
    .special {
      grid-column: 1;
      grid-row: 3 / 6;
    }
    .amazing {
      grid-column: 1;
      grid-row: 6;
    }
    .cta {
      grid-column: 2;
      grid-row: 5 / 7;
    }
  }
  
  @media (min-width: 800px) {
     .homeHero {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: minmax(150px, auto) minmax(150px, auto);
      grid-auto-rows: minmax(100px, auto);
    }
    .feature {
      grid-column: 1 ;
      grid-row: 1 / 6;
    }
    
    .special {
      grid-column: 2;
      grid-row: 1 / 4;
    }
    .amazing {
      grid-column: 2;
      grid-row: 4 / 6;
    }
    .cta {
      grid-column: 3;
      grid-row: 3 / 6;
    }
  }


  .feature img{
    object-fit: cover;
  }

  @media (max-width: 600px) {
    .special h3,
    .feature h3,
    .news h3,
    .photos h3,
    .cta h3,
    .amazing h3{
      font-size: 15px;
      font-family: Dana2;
      margin-bottom: 0px !important;
    }
    .news,
    .photos,
    .cta{
      display: none;
    }
  }