.postDiv{
    width: 100%;
    margin-top: 20px;
    background-color: #F8FBFE;
    width: 100%;
    height: 100%;
    background-color: #F8FBFE;
    border-radius: 15px;
    transition: 0.3s  ease-in-out all;
    -webkit-box-shadow: 0px 0px 23px -12px rgba(0,41,173,0.49); 
    box-shadow: 0px 0px 23px -12px rgba(0,41,173,0.49);
}
.topTextDiv{
    text-align: right;
}
.topTextDiv h3{
    line-height: 35px;
    font-family: Dana1;
}
.coverImage{
    width: 100%;
    max-height: 60vh;
}
.coverImage img{
    width: 100%;
    max-height: 60vh;

    border-radius: 8px;
    border: 4px solid #CCD9F7;
    background-repeat: none;
    background-position: center;
    object-fit: cover;
}
.line{
    height: 2px;
    width: 100%;
    background-color: #35406377;
}
.dateDiv{
    display: inline-block;
    margin-top: 15px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
}
.dateDiv h4{
    
}
.content{
    margin-top: 20px;
}
a{
    color: black !important;
}

