@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@font-face {
  font-family: "Dana1";
  src: url("./woff/dana-bold.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Dana2";
  src:   url("./woff/dana-medium.woff") format("woff");
  font-weight: normal;
  
  }
@font-face {
  font-family: "Dana3";
  src:   url("./woff/dana-light.woff") format("woff");
  font-weight: lighter;
      
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./woff/Roboto-Bold.ttf") format("ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto-Medium";
  src:   url("./woff/Roboto-Medium.ttf") format("ttf");
  font-weight: normal;
  
  }
@font-face {
  font-family: "Roboto-Regular";
  src:   url("./woff/Roboto-Regular.ttf") format("ttf");
  font-weight: lighter;
      
}
* {
  box-sizing: border-box;
}

html {
  font-family: Dana2, 'Noto Sans JP', sans-serif;
}
a{
  text-decoration: none;

}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #F8FBFE;
  overflow-x: hidden;
  padding: 1px 0 0 0 !important;
  margin: -1px auto 0 auto !important;
  font-family:Dana2 ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
