
/*===================== carousel section =====================*/

.carouselBannerDiv{
    padding: 0px 0px 0px 0px;
    margin-top: 88px;
    
}



/*===================== brief card section =====================*/
.briefCardDiv{
    max-width: 1500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    height: 100%;
    padding: 0px 40px 0px 40px;
    
}


.briefCardFirstCol{
    padding: 0px 5px 10px 0px;
}
.briefCardSecondCol{
    padding: 0px 0px 10px 5px;
}
.briefCardThirdCol{
    padding: 0px 5px 10px 0px;
}
.briefCardFourthCol{
    padding: 0px 0px 10px 5px;
}
/*===================== card slider =====================*/

.cardSliderDiv{

        max-width: 1700px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 60px 0px 60px;
}

/*===================== about us tiles =====================*/
.aboutUsTilesDiv{

    margin-top: 55px;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 0px 60px 0px 60px;
}

.aboutUsTilesDiv h2{
    color: #1043A9;
    font-family: Dana1;
    text-align: center;
    margin-bottom: 30px;
}

.firstAboutUsTilesDiv{
    width: 100%;
    max-width: 548px !important;
    max-height: 480px;
    border-radius: 20px;
    height: 100%;
    margin-right: 5px;
    padding: 0px 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49); 
    box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49);
}
.secondAboutUsTilesDiv{
    width: 100%;
    max-width: 548px !important;
    max-height: 480px;
    height: 100%;
    border-radius: 20px;
    margin-left: 5px;
    padding: 0px 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49); 
    box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49);
}

.thirdAboutUsTilesDiv{
    border-radius: 20px;
    margin-top: 10px;
    -webkit-box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49); 
box-shadow: 0px 0px 23px -3px rgba(0,41,173,0.49);
}

.firstAboutUsTilesDiv img{
    object-fit: cover;
    width: 100%;
    max-width: 548px !important;
    max-height: 480px;
    height: 100%;
    border-radius: 20px;
}
.secondAboutUsTilesDiv img{
    object-fit: cover;
    width: 100%;
    max-width: 548px !important;
    max-height: 480px;
    height: 100%;
    border-radius: 20px;
}
.thirdAboutUsTilesDiv img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
/*===================== oprators card div =====================*/
.opratorsSecDiv h2{
    color: #1043A9;
    font-family: Dana1;
    text-align: center;
    margin-bottom: 25px;    

}
.opratorsSecDiv{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 55px;
    height: 100%;
    padding: 0px 60px 0px 60px;
}
.firstOpratorDiv{
    width: 100%;
    max-width: 525px !important;

    height: 100%;
    background-color: none;
    max-height: 680px;
    border-radius: 20px;
}
.secondOpratorDiv{
    width: 100%;
    max-width: 525px !important;

    height: 100%;
    background-color: none;
    max-height: 680px;
    border-radius: 20px;

}
.opratorsSecDiv img {
    object-fit: cover;
    max-width: 525px !important;

    width: 100%;
    max-height: 680px;
    border-radius: 20px;
}

.loaderDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;

}







@media only screen and (max-width: 1200.98px) {

    /*===================== brief card section =====================*/

    .briefCardFirstCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardSecondCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardThirdCol0px{
        padding: 0px 0px 10px 0px;
    }
    .briefCardFourthCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardDiv{
        max-width: 1500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 180px 0px 180px;
        
    }


}


@media only screen and (max-width: 992.98px) {
    /*===================== carousel section =====================*/
    .carouselBannerDiv{
        padding: 0px 0px 0px 0px;
        margin-top: 113px !important;
        
    }
    /*===================== brief card section =====================*/


    .briefCardDiv{
        max-width: 1500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 40px 0px 40px;
        
    }
    
    
    .briefCardFirstCol{
        padding: 0px 5px 10px 0px;
    }
    .briefCardSecondCol{
        padding: 0px 0px 10px 5px;
    }
    .briefCardThirdCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardFourthCol{
        padding: 0px 0px 10px 5px;
    }
        /*===================== about us tiles =====================*/
    
        .aboutUsTilesDiv h2{

            color: #1043A9;
            font-family: Dana1;
            text-align: center;
            margin-bottom: 20px;
        }
        
}

@media only screen and (max-width: 768.98px) {

    
    /*===================== brief card section =====================*/

    .briefCardFirstCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardSecondCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardThirdCol0px{
        padding: 0px 0px 10px 0px;
    }
    .briefCardFourthCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardDiv{
        max-width: 1500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 40px 0px 40px;
        
    }


        /*===================== oprators card div =====================*/

        .opratorsSecDiv h2{
            color: #1043A9;
            font-family: Dana1;
            text-align: center;
            margin-bottom: 25px;    
        
        }
        .opratorsSecDiv{
            max-width: 1200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 55px;
            height: 100%;
            padding: 0px 60px 0px 60px;
        }
        /*===================== about us tiles =====================*/
        .thirdAboutUsTilesDiv{
            width: 100%;
            border-radius: 10px;
            max-height: 190px;
            height: 100%;
            margin-top: 20px;
        }
        
        .aboutUsTilesDiv h2{
            color: #1043A9;
            font-family: Dana1;
            text-align: center;
            margin-bottom: 30px;
        }
        .firstAboutUsTilesDiv{
            width: 100%;
            max-width: 552px !important;
            max-height: 480px;
            border-radius: 10px;
            height: 100%;
            margin-left: 0px;
            margin-right: auto;
            padding: 0px 5px 0px 0px;
        }
        .secondAboutUsTilesDiv{
            width: 100%;
            max-width: 552px !important;
            max-height: 480px;
            height: 100%;
            border-radius: 10px;
            margin-left: auto;
            margin-right: 0px;
            padding: 0px 0px 0px 5px;
        
        }
        
        .thirdAboutUsTilesDiv{
            border-radius: 10px;
            margin-top: 10px;
        }
        
        .firstAboutUsTilesDiv img{
            object-fit: cover;
            width: 100%;
            max-width: 552px !important;
            max-height: 480px;
            height: 100%;
            border-radius: 10px;
        }
        .secondAboutUsTilesDiv img{
            object-fit: cover;
            width: 100%;
            max-width: 552px !important;
            max-height: 480px;
            height: 100%;
            border-radius: 10px;
        }
        .thirdAboutUsTilesDiv img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        /*===================== card slider =====================*/

        .cardSliderDiv{

            max-width: 1700px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            height: 100%;
            padding: 0px 30px 0px 30px;
        }

 }

 @media only screen and (max-width: 576px) {



    

    /*===================== brief card section =====================*/

    .briefCardFirstCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardSecondCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardThirdCol0px{
        padding: 0px 0px 10px 0px;
    }
    .briefCardFourthCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardDiv{
        max-width: 1500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 30px 0px 30px;
        
    }



/*===================== card slider =====================*/

.cardSliderDiv{

        max-width: 1700px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 14px 0px 14px;
}
/*===================== about us tiles =====================*/
.aboutUsTilesDiv{

    margin-top: 50px;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 0px 35px 0px 35px;
    
}

.aboutUsTilesDiv h2{
    font-size: 23px;
    color: #1043A9;
    font-family: Dana1;
    text-align: center;
    margin-bottom: 15px;
}



    /*===================== oprators card div =====================*/

    .opratorsSecDiv h2{
        font-size: 23px;
        color: #1043A9;
        font-family: Dana1;
        text-align: center;
        margin-bottom: 15px;    
    
    }
    .opratorsSecDiv{
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
        height: 100%;
        padding: 0px 35px 0px 35px;
    }
    .firstOpratorCol{
        padding: 0px 5px 0px 0px;
        border-radius: 10px;
    }
    .secondOpratorCol{
        padding: 0px 0px 0px 5px;
        border-radius: 10px;

    }

    .opratorsSecDiv img {
        object-fit: cover;
        width: 100%;
        max-height: 580px;
        border-radius: 10px;
    }
    
    
 }

 @media only screen and (max-width: 356px) {
         /*===================== brief card section =====================*/

    .briefCardFirstCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardSecondCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardThirdCol0px{
        padding: 0px 0px 10px 0px;
    }
    .briefCardFourthCol{
        padding: 0px 0px 10px 0px;
    }
    .briefCardDiv{
        max-width: 1500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 100%;
        padding: 0px 25px 0px 25px;
        
    }


    /*===================== card slider =====================*/

.cardSliderDiv{

    max-width: 1700px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    height: 100%;
    padding: 0px 3px 0px 3px;
}
    /*===================== oprators card div =====================*/

    .opratorsSecDiv h2{
        font-size: 18px;
        color: #1043A9;
        font-family: Dana1;
        text-align: center;
        margin-bottom: 15px;    
    
    }
    .opratorsSecDiv{
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
        height: 100%;
        padding: 0px 30px 0px 30px;
    }
    .firstOpratorCol{
        padding: 0px 5px 0px 0px;
        border-radius: 10px;
    }
    .secondOpratorCol{
        padding: 0px 0px 0px 5px;
        border-radius: 10px;
    }

    /*===================== about us tiles =====================*/
.aboutUsTilesDiv{

    margin-top: 50px;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 0px 30px 0px 30px;
    
}

.aboutUsTilesDiv h2{
    font-size: 18px;
    color: #1043A9;
    font-family: Dana1;
    text-align: center;
    margin-bottom: 15px;
}
    
 
 }



    /*===================== blog section =====================*/
 .blogSectionDiv{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 55px;
    height: 100%;
    padding: 30px 20px 30px 20px;
    background-color: rgb(241, 241, 241);
    border-radius: 30px;
    
    text-align: right;
}
.showAll{
    width: 100%;
    text-align: center;
    
}
.blogSectionDiv h2{
    text-align: center;
    font-family: Dana1;
    font-size: 30px;
    padding: 14px 20px 14px 20px;
    color: #1043A9;
}

.blogSectionDiv h4{
    color: #1043A9;
    font-size: 19px;
    display: inline-block;
    margin: 0px auto 0px auto;
    
}
@media (max-width: 600px) {
    .blogSectionDiv h2{
        font-family: Dana1;
        font-size: 24px;
        padding: 14px 20px 14px 20px;
        color: #1043A9;
    }
    .blogSectionDiv{
        max-width: 1200px;
        width: 93%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
        height: 100%;
        padding: 20px 10px 20px 10px;
        background-color: rgb(241, 241, 241);
        border-radius: 30px;
        text-align: right;
    }
}