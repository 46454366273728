.priceLimiterDiv{
    background-color:#FFFFFF ;
    -webkit-box-shadow: 0px 0px 23px -12px rgba(0,41,173,0.49); 
    box-shadow: 0px 0px 23px -12px rgba(0,41,173,0.49);
    border-radius:5px ;
    margin-top: 50px;
}
.priceLimiterDiv h4{
    font-size: 15px;
    color: #354063;
    padding:18px 14px 4px 0px ;
    font-family: Dana1;
}
.limiterDiv{
    width: 100%;
    text-align: center;
    margin: 10px auto 0px auto;
}

.priceDiv{
    width: 100%;
    text-align: center;
    margin: 0px auto 0px auto;
    padding-bottom: 16px;
    font-size: 14px;
    color: #354063;
}
.priceDiv span{

    color: #1043A9;
}