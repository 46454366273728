.showMoreStyle{
    color: rgb(0, 0, 0) !important;

}
.showLessOrMore{
    color: #005980 !important;
    
}
.showLessOrMore:hover{
    color: #90e5f0 ;
}
.showMoreStyle span{
    color:#000000 !important;
    font-family: Dana2;
    line-height: 28px;
    font-size: 14px;
    text-justify: auto;

}


@media only screen and (max-width: 768.98px) {



 }

 @media only screen and (max-width: 576px) {


    .showMoreStyle span{
        color:#000000 !important;
        font-family: Dana2;
        line-height: 5px;
        font-size: 10px;
        text-justify: auto;
        
        width: 100%;
        padding: 0px;
    }
    .showMoreStyle{
        margin-top: 5px;
        margin-bottom: 3px;

    }
    

    
 }

 @media only screen and (max-width: 356px) {

 }
