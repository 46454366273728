.divContainer{
    background-color: #EEEEEE !important;
    min-height:100vh;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    align-items: center;
    width: 100%;
    
}   
.introDiv{
    width: 100%;
    
}
.blueSection{
    height: 50%;
    background-color: #C9E5F3;
}
.animatedSectionDiv{
    background-color: #C9E5F3 !important;
    max-width: 210px;
    max-height: 210px;
    width: 100%;
    border-radius: 1000px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    padding: 15px;
    margin-top: 80px;
}
.introDiv h1{
    color: #1043A9;
    font-family: Dana1;
    font-size: 25px;
    text-align: center;
    margin: 0px auto 0px auto;
    margin-top: 15px;
    margin-bottom: 20px;
}


.listDiv{
    max-width: 1000px;
    width: 100%;
    margin: 0px auto 0px auto;

}
.listDiv ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-top: 16px;
}
.listDiv ul li{
    cursor: pointer;
    width: 100%;
    background-color: #E2E2E2;
    border-radius: 12px;
    padding: 0px;
    margin: 8px 0px 0px 0px ;
}
.lightBlue{
    background-color: #C9E5F3 !important;
}
.listDiv ul li:hover{
 
    background-color: #dcdcdc;

}
.cityLable{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 7px 18px 7px 18px;
    color: #C9E5F3;
    background-color: #1043A9;
}
.cityLable h5{
    font-weight: 800;
    margin: 0px;
    text-align: center;
    font-size: 19px;
}
.address{
    height: 100%;
    padding: 0px 8px 0px 8px;
    color: #1043A9;
    text-align: right;

}
.address h5{
    height: 100%;
    text-align: right;

    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 15px !important;
}   
.addressOman{
    display: inline-block;
    padding: 0px 8px 0px 8px;
    color: #1043A9;
}
.addressOman h5{
    font-size: 15px !important;
}  
.showMoreBtnDiv{
    display: inline-block;
    margin-left: 15px;
    margin-top: 5px;
    border: none;
    float: left;    
}
.showMoreBtnDiv button{
    font-size: 12px;
    color:#020031;
    border: none;
    background-color: none;
    border-radius: 100px;
    padding: 2px 13px 2px 6px;
}
.newsDiv{
    background-color: #FFFFFF;
    border: 3px #000000 solid;
    border-radius: 12px;
    margin-top: 10px;
}
.headerDiv{
    padding: 20px 15px 20px 0px;
    border-bottom: solid 3px #000000;
}   
.headerDiv h5{
    margin: 0px;
    font-weight: 900;
}
.imageDiv{
    padding: 6px 6px 0px 6px;
}
.imageDiv h4{
    font-size: 18px;
    text-align: center;
    font-weight: 800;
    padding: 10px 0px 10px 0px;
}
.imageDiv img{
    height: 400px;
    width:100%;
    border: 3px solid #000000;
    object-fit: cover;

}
.imgSubDiv{
    padding: 6px;
}
.imgSubDiv img{
    width: 100%;
    height: 80px;
    border: 3px solid #000000;
    object-fit: cover;

}
.vertDiv{
    border-left: solid 3px #000000;
}
.aboutBranchHeadDiv{
    padding: 0px 6px 0px 6px;
    border-bottom: solid 3px #000000;
}
.aboutBranchHeadDiv h4{
    font-size: 18px;
    text-align: center;
    padding: 10px 0px 10px 0px;
    margin: 0px;
    font-weight: 800;
}
.aboutBranchTextDiv p{
    padding: 0px 10px 0px 20px;
    margin-top: 10px;
    line-height: 32px;
    font-size: 15px;
}
.videoTitleDiv{
    border: 3px solid #000000;
    text-align: center;
    border-left: 0px;
    border-right: 0px;

}
.videoTitleDiv h5{
    margin: 0px;
    padding: 10px 0px 10px 0px;
    font-weight: 800;
}
.videoDiv{
   max-width: 1920px;
   width: 100%;
   padding: 6px;

}
.videoDiv video{
    width: 100%;
    height: 100%;
    
 }

 .rotateIn{
   
    animation: arrowUp ease-in-out 0.2s forwards;
}
.rotateOut{
       

    animation: arrowDown ease-in-out 0.2s forwards;

}
    .btnEng{
    padding-right: 5px !important;
    padding-left: 10px !important;
    }
@keyframes arrowUp {
    0% {
       
        transform:rotate(0deg);
    }

    100% {
        transform:rotate(180deg);
    }
}
@keyframes arrowDown {
    0% {
        transform:rotate(180deg);
    }

    100% {
        transform:rotate(0deg);
    }
}
@media only screen and (max-width: 1200.98px) {

}
@media only screen and (max-width: 992.98px) {
    .cityLable{
        height: 100%;
        display: flex;

        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: #C9E5F3;
        background-color: #1043A9;
    }
    .cityLable h5{
        font-weight: 800;
        margin: 0px;       
         padding: 7px 12px 7px 12px;

        text-align: center;
        padding: 0px;
        font-size: 15px;
    }
    .address{
        display: inline-block;
        padding: 0px 8px 0px 8px;
        color: #1043A9;
    }
    .address h5{
        font-size: 14px !important;
    }  
    .showMore{
        display: none;
    } 
    .showMoreBtnDiv button{
        padding: 1px 1px 1px 1px;
        margin-left: -10px;
    }
    .showMoreBtnDiv{
        display: inline-block;
        margin-top: 3px;
        border: none;
        float: left;    
    }
    .addressOman{

        padding: 0px 8px 0px 8px;
        color: #1043A9;
        max-width: 350px;
    }
    .addressOman h5{
        font-size: 12px !important;
        justify-content: center;
        display: flex;
        align-items: center;
    } 
    .btnEng{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

}
@media only screen and (max-width: 768.98px) {
    .cityLable{
        height: 100%;
        display: flex;

        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: #C9E5F3;
        background-color: #1043A9;
    }
    .cityLable h5{
        font-weight: 800;
        margin: 0px;       
         padding: 7px 12px 7px 12px;

        text-align: center;
        padding: 0px;
        font-size: 13px;
    }
    .address{
        display: inline-block;
        padding: 0px 8px 0px 8px;
        color: #1043A9;
    }
    .address h5{
        font-size: 12px !important;
    }  
    .showMore{
        display: none;
    } 
    .showMoreBtnDiv button{
        padding: 1px 1px 1px 1px;
        margin-left: -10px;
    }
    .showMoreBtnDiv{
        display: inline-block;
        margin-top: 3px;
        border: none;
        float: left;    
    }
    .addressOman{

        padding: 0px 8px 0px 8px;
        color: #1043A9;
        max-width: 350px;
    }
    .addressOman h5{
        font-size: 12px !important;
        justify-content: center;
        display: flex;
        align-items: center;
    }  
    .introDiv h1{
        color: #1043A9;
        font-family: Dana1;
        font-size: 22px;
        text-align: center;
        margin: 0px auto 0px auto;
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .introDiv h1{

        font-size: 21px;
        margin-bottom: 12px;
    }
    .btnEng{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
 }
@media only screen and (max-width: 576px) {
 

}
 @media only screen and (max-width: 356px) {

 }