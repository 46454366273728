.noDataDiv{
    text-align: center;
}
.img{
    width: 200px;
}
.msg{
    margin-top: 18px;
    font-family: Dana1;
    font-size: 24px;
    color: #9194a0;
}

@media only screen and (max-width: 992.98px) {
    .img{
        width:140px;
    }
    .msg{
        margin-top: 10px;
        font-family: Dana1;
        font-size: 20px;
        color: #9194a0;
    }

}
@media only screen and (max-width: 768.98px) {
    .img{
        width:100px;
    }
    .msg{
        margin-top: 10px;
        font-family: Dana1;
        font-size: 15px;
        color: #9194a0;
    }
}